import { createSlice } from "@reduxjs/toolkit";

import { claimEarnQuest, getEarnQuests, verifyEarnQuest } from "./earn.thunk";

export interface IEarnState {
    isLoading: boolean;
    data: any | null;
}

const initialState: IEarnState = {
    data: null,
    isLoading: false,
};

export const earnSlice = createSlice({
    initialState,
    name: "earn",
    reducers: {
        clearOnboarding: (state) => {
            state.data = null;
        },
        setOnboarding: (state) => {
            state.data = [];
        },
        setQuestsList: (state, { payload }) => {
            state.data = payload.data;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getEarnQuests.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getEarnQuests.fulfilled, (state, action) => {
                state.data = action.payload;
                state.isLoading = false;
            })
            .addCase(getEarnQuests.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(verifyEarnQuest.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(verifyEarnQuest.fulfilled, (state, { payload }) => {
                const questId = payload?.data?.id;
                if (!questId) return;

                const elementIndex = state.data.data.findIndex(
                    (_: any) => _.id === questId
                );

                if (elementIndex === -1) return;

                state.data.data[elementIndex] = payload.data;
                state.isLoading = false;
            })
            .addCase(verifyEarnQuest.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(claimEarnQuest.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(claimEarnQuest.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(claimEarnQuest.rejected, (state) => {
                state.isLoading = false;
            });
    },
});

export const { clearOnboarding, setOnboarding, setQuestsList } =
    earnSlice.actions;

export default earnSlice.reducer;
