import { createAsyncThunk } from "@reduxjs/toolkit";

import { EarnAPI } from "@services/endpoints/earn/Earn";

export const getEarnQuests = createAsyncThunk("earn/quests", async () => {
    const response = await EarnAPI.getQuests();
    return response.data;
});

export const verifyEarnQuest = createAsyncThunk(
    "earn/quests/verify",
    async (id: string) => {
        const response = await EarnAPI.verifyQuests(id);
        return response.data;
    }
);

export const claimEarnQuest = createAsyncThunk(
    "earn/quests/claim",
    async (id: string) => {
        const response = await EarnAPI.claimQuests(id);
        return response.data;
    }
);
