import { FC, memo, useState } from "react";

import clsx from "clsx";
import { useNavigate } from "react-router-dom";

import { routes } from "@constants/routes";

import { useAppDispatch } from "@hooks/useAppDispatch";

import Button from "@atoms/Button";
import Typography from "@atoms/Typography";

import Modal from "@molecules/Modal";

import bg from "@images/shelfModalBg.svg";

import { openLootBox } from "@redux/lootboxes/lootboxes.thunk";
import { updateUserData } from "@redux/users/users.slice";

import {
    defaultStage,
    presentsByAmountAndStage,
} from "./ShelfPerksModal.const";
import styles from "./ShelfPerksModal.module.scss";
import {
    IShelfPerksModal,
    TShelfPerksModalStage,
} from "./ShelfPerksModal.types";

const ShelfPerksModal: FC<IShelfPerksModal> = ({
    btnPointsAmount,
    presentsAmount,
    lootBoxId,
    selectedLootBox,

    onClose,
    ...modalProps
}) => {
    const dispatch = useAppDispatch();
    const [stage, setStage] = useState<TShelfPerksModalStage>(defaultStage);
    const [presentHints, setPresentHints] = useState<string[]>([]);
    const navigate = useNavigate();

    const handleGetOpenStage = async () => {
        if (stage === "open") {
            navigate(routes.perks);
            return;
        }

        const { payload } = (await dispatch(
            openLootBox({ lootBoxId, count: presentsAmount })
        )) as any;

        const rewardItems = payload?.items.map((_: any) =>
            _.isAddToInventory ? `${_.amount} ${_.title}` : `${_.title}`
        );

        if (payload.user) {
            dispatch(updateUserData(payload.user));
        }

        setPresentHints(rewardItems);
        setStage("open");
    };

    const handleClose = () => {
        onClose();
        setStage(defaultStage);
    };

    const isCloseStage = stage === "close";
    const isOpenStage = stage === "open";

    const hasOnePresent = presentsAmount === 1;
    const hasTwoPresents = presentsAmount === 2;
    const hasThreePresents = presentsAmount === 3;

    return (
        <Modal
            {...modalProps}
            title="Confirm purchase"
            titleClassName={styles.title}
            onClose={handleClose}
        >
            <div
                className={clsx(styles.content, {
                    [styles.content_open]: isOpenStage,
                })}
            >
                {isCloseStage && (
                    <Typography
                        className={styles.subtitle}
                        variant="text2Regular"
                    >
                        {presentsAmount} Lootbox
                        {presentsAmount! > 1 ? "es" : ""} to be open
                    </Typography>
                )}

                <img alt="" className={styles.bg} src={bg} />
                <div
                    className={clsx(styles.present, {
                        [styles.present_open]: stage === "open",
                    })}
                >
                    <img
                        alt=""
                        src={presentsByAmountAndStage[stage][presentsAmount!]}
                    />

                    {isOpenStage &&
                        presentHints.map((text, index) => (
                            <Typography
                                key={index}
                                className={clsx(styles.point, {
                                    [styles.point_one]: hasOnePresent,
                                    [styles["point_two-left"]]:
                                        hasTwoPresents && index === 0,
                                    [styles["point_two-right"]]:
                                        hasTwoPresents && index === 1,
                                    [styles["point_three-left"]]:
                                        hasThreePresents && index === 0,
                                    [styles["point_three-mid"]]:
                                        hasThreePresents && index === 1,
                                    [styles["point_three-right"]]:
                                        hasThreePresents && index === 2,
                                })}
                                variant="textSmall"
                            >
                                {text}
                            </Typography>
                        ))}
                </div>

                <Button className={styles.btn} onClick={handleGetOpenStage}>
                    {stage === "open" ? (
                        <span>Go to perks list</span>
                    ) : (
                        <span>
                            Pay {btnPointsAmount?.toLocaleString("ru-RU") || 0}{" "}
                            points
                        </span>
                    )}
                </Button>
            </div>
        </Modal>
    );
};

export default memo(ShelfPerksModal);
