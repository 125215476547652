import { FC, memo } from "react";

import Button from "@atoms/Button";

import Modal from "@molecules/Modal";

import styles from "./InviteFrenModal.module.scss";
import { IInviteFrenModal } from "./InviteFrenModal.types";

const InviteFrenModal: FC<IInviteFrenModal> = ({
    onClose,
    onClickSend,
    open,
    onCopyToClipboard,
}) => {
    return (
        <Modal open={open} title="Invite a fren" onClose={onClose}>
            <div className={styles.content}>
                <Button onClick={onClickSend}>Send</Button>
                <Button onClick={onCopyToClipboard}>Copy link</Button>
            </div>
        </Modal>
    );
};

export default memo(InviteFrenModal);
