import { memo, useEffect, useMemo, useRef, useState } from "react";

import clsx from "clsx";

import { useAppDispatch } from "@hooks/useAppDispatch";
import { useAppSelector } from "@hooks/useAppSelector";

import { StandardSunSpinner } from "@atoms/SunSpinner/StandardSunSpinner";
import Typography from "@atoms/Typography";
import WindowBlock from "@atoms/WindowBlock";

import { leaderBoardSelector } from "@redux/leaderboards/leaderboards.selectors";
import { getLeaderBoardsData } from "@redux/leaderboards/leaderboards.thunk";

import styles from "./LeaderBoardList.module.scss";

const LeaderBoardList = () => {
    const [limit] = useState(100);
    const [offset, setOffset] = useState(0);

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getLeaderBoardsData({ limit, offset }));
    }, [dispatch, limit, offset]);

    const { stats, leaders, isLoading, receivedLeadersCount } =
        useAppSelector(leaderBoardSelector);

    const leaderBoards: any = useMemo(() => {
        return ([{ ...stats, isActive: true }] as any).concat(leaders);
    }, [leaders, stats]);

    const anchorRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        handleScrollToAnchor();
                    }
                });
            },
            { threshold: 1 }
        );

        if (anchorRef.current) {
            observer.observe(anchorRef.current);
        }

        return () => {
            if (anchorRef.current) {
                observer.unobserve(anchorRef?.current);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [anchorRef?.current]);

    const handleScrollToAnchor = () => {
        if (receivedLeadersCount === limit && !isLoading) {
            setOffset((offset) => offset + limit);
        }
    };

    if (isLoading) {
        return <StandardSunSpinner />;
    }

    return (
        <WindowBlock className={styles.wrapper}>
            <div className={styles.list}>
                <div className={styles.head}>
                    <div className={styles.row}>
                        <Typography
                            className={clsx(styles.id, styles["cell-head"])}
                            variant="captionRegular"
                        >
                            #
                        </Typography>
                        <Typography
                            className={clsx(styles.name, styles["cell-head"])}
                            variant="captionRegular"
                        >
                            Nickname
                        </Typography>
                        <Typography
                            className={clsx(styles.score, styles["cell-head"])}
                            variant="captionRegular"
                        >
                            Score
                        </Typography>
                    </div>
                </div>
                <div className={styles.body}>
                    {leaderBoards.map(
                        (
                            { position, nickName, earn, isActive }: any,
                            index: number
                        ) => (
                            <div
                                key={index}
                                className={clsx(styles.row, {
                                    [styles.row_active]: isActive,
                                })}
                            >
                                <Typography
                                    className={clsx(
                                        styles.id,
                                        styles["cell-body"]
                                    )}
                                    variant="captionRegular"
                                >
                                    {position}
                                </Typography>
                                <Typography
                                    className={clsx(
                                        styles.name,
                                        styles["cell-body"]
                                    )}
                                    variant="captionRegular"
                                >
                                    {nickName}
                                </Typography>
                                <Typography
                                    className={clsx(
                                        styles.score,
                                        styles["cell-body"]
                                    )}
                                    variant="captionRegular"
                                >
                                    {earn}
                                </Typography>
                            </div>
                        )
                    )}
                    {leaders.length > 0 && <div ref={anchorRef} />}
                </div>
            </div>
        </WindowBlock>
    );
};

export default memo(LeaderBoardList);
