import { FC } from "react";

import { ISvg } from "./SVG.types";

export const XWebSVG: FC<ISvg> = ({ className }) => {
    return (
        <svg
            className={className}
            fill="none"
            height="16"
            viewBox="0 0 14 16"
            width="14"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.6453 14.6235L8.69065 6.83756L13.5969 1.43756C13.6446 1.39328 13.6826 1.33956 13.7084 1.27981C13.7342 1.22006 13.7472 1.15559 13.7467 1.09051C13.7463 1.02543 13.7322 0.961165 13.7056 0.901805C13.6789 0.842445 13.6401 0.789292 13.5918 0.745731C13.5434 0.70217 13.4865 0.669154 13.4247 0.648788C13.3629 0.628421 13.2975 0.62115 13.2327 0.627436C13.168 0.633722 13.1052 0.653429 13.0485 0.6853C12.9917 0.717172 12.9422 0.760511 12.9032 0.812557L8.1719 6.01724L4.89847 0.873494C4.85587 0.806521 4.79697 0.75146 4.72728 0.713473C4.65759 0.675485 4.5794 0.655815 4.50003 0.656307H0.750028C0.666059 0.656346 0.583642 0.678939 0.511391 0.721726C0.439141 0.764513 0.37971 0.825922 0.339311 0.899534C0.298912 0.973146 0.279029 1.05626 0.281738 1.14018C0.284448 1.22411 0.309652 1.30577 0.354716 1.37662L5.3094 9.16256L0.403153 14.5626C0.332857 14.6562 0.300145 14.7727 0.311455 14.8893C0.322764 15.0058 0.377275 15.1139 0.464262 15.1923C0.55125 15.2706 0.664416 15.3136 0.781495 15.3127C0.898574 15.3119 1.01109 15.2672 1.0969 15.1876L5.82815 9.98287L9.10159 15.1266C9.14418 15.1936 9.20309 15.2487 9.27278 15.2866C9.34246 15.3246 9.42066 15.3443 9.50003 15.3438H13.25C13.334 15.3438 13.4164 15.3212 13.4887 15.2784C13.5609 15.2356 13.6203 15.1742 13.6607 15.1006C13.7011 15.027 13.721 14.9439 13.7183 14.8599C13.7156 14.776 13.6904 14.6943 13.6453 14.6235ZM9.75706 14.4063L1.60393 1.59381H4.243L12.3961 14.4063H9.75706Z"
                fill="white"
            />
        </svg>
    );
};
