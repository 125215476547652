import clsx from "clsx";

import Typography from "@atoms/Typography";

import styles from "./InfoCardsList.module.scss";
import { IInfoCardsList } from "./InfoCardsList.types";

const InfoCardsList: FCC<IInfoCardsList> = ({ children, title, className }) => {
    return (
        <div className={clsx(styles.wrapper, className)}>
            <Typography className={styles.title} variant="h3Bold">
                {title}
            </Typography>

            <div className={styles.list}>{children}</div>
        </div>
    );
};

export default InfoCardsList;
