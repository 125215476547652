import { memo } from "react";

import { StandardSunSpinner } from "@atoms/SunSpinner/StandardSunSpinner";

import styles from "./LoadingScreen.module.scss";

const LoadingScreen = () => {
    return (
        <div className={styles.wrapper} style={{}}>
            <StandardSunSpinner />
        </div>
    );
};

export default memo(LoadingScreen);
