import { createElement } from "react";

import clsx from "clsx";

import styles from "./Typography.module.scss";
import { ITypography } from "./Typography.types";

const Typography: FCC<ITypography> = ({
    element = "div",
    children,
    variant = "text",
    className,
    ...otherProps
}) => {
    return createElement(
        element,
        {
            className: clsx(className, styles[variant]),
            ...otherProps,
        },
        children
    );
};

export default Typography;
