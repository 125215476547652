import { AxiosPromise } from "axios";

import Request from "@services/Request";

import { API_URLS } from "@constants/api";

const url = API_URLS.getEarn;

export class EarnAPI {
    static getQuests(): AxiosPromise<any> {
        return Request.get(`${url}`);
    }

    static verifyQuests(id: string): AxiosPromise<any> {
        return Request.post(`${url}${id}/`, {});
    }

    static claimQuests(id: string): AxiosPromise<any> {
        return Request.post(`${url}${id}/claim/`, {});
    }
}
