import { AxiosPromise } from "axios";

import Request from "@services/Request";

import { API_URLS } from "@constants/api";

import { ILeaderBoardsData } from "@schemas/leaderboards";

import { ILimitOffset } from "./Frens";

const url = API_URLS.getLeaderBoards;

export class LeaderBoardsAPI {
    static async getLeaderBoardsData({
        limit,
        offset,
    }: ILimitOffset): AxiosPromise<ILeaderBoardsData> {
        return Request.get(`${url}`, { params: { limit, offset } }).then(
            (response) => {
                if (!response.data.success)
                    throw new Error(response.data.message);
                return response.data;
            }
        );
    }
}
