import { memo } from "react";

import { Toaster } from "react-hot-toast";

import { SuccessSVG } from "@svg/SuccessSVG";

import styles from "./ToasterComponent.module.scss";

const ToasterComponent = () => {
    return (
        <Toaster
            containerStyle={{}}
            position="top-center"
            toastOptions={{
                className: styles.wrapper,
                icon: <SuccessSVG />,
            }}
        />
    );
};

export default memo(ToasterComponent);
