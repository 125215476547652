import axios from "axios";

import { BASE_URL, Headers } from "@constants/api";

import {
    setServerNotAvailableError,
    setSomethingWentWrongError,
} from "@redux/app/app.slice";
import { store } from "@redux/store";

const axiosInstanceJSON = {
    baseURL: BASE_URL,
    headers: Headers,
    timeout: 10000,
};

export const axiosInstance = axios.create(axiosInstanceJSON);

const MAX_REFETCH_RETRIES = 5;
const RETRY_REFETCH_DELAY = 3000;

const retryRequest = (error: any) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(axiosInstance.request(error.config));
        }, RETRY_REFETCH_DELAY);
    });
};
// интерцептор нельзя выносить в другой файл, не отрабатывает
axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        const retryRefetchCount = error.config.__retryCount || 0;
        const isRetryExceeded = retryRefetchCount >= MAX_REFETCH_RETRIES;

        if (error) {
            console.error("Request failed:", error);
        }

        if (!error.response || error.code === "ECONNABORTED") {
            // Если ошибка сетевого уровня (сервер не отвечает)
            store.dispatch(setServerNotAvailableError(true));
        } else {
            store.dispatch(setServerNotAvailableError(false));
        }

        if (
            error.response?.status === 500 ||
            error.response?.message?.code === "WRONG" ||
            isRetryExceeded
        ) {
            store.dispatch(setSomethingWentWrongError(true));
        } else {
            store.dispatch(setSomethingWentWrongError(false));
        }

        if (error.response?.code === "REFETCH" && !isRetryExceeded) {
            error.config.__retryCount = retryRefetchCount + 1;
            return retryRequest(error);
        }

        return Promise.reject(error);
    }
);
