import { createSlice } from "@reduxjs/toolkit";

import { getReferralData } from "./referral.thunk";

export interface IReferralState {
    code: string;
    count: number;
    earn: number;
    earnGamePasses: number;
    isLoading: boolean;
    referralLink: string;
}

const initialState: IReferralState = {
    code: "",
    count: 0,
    earn: 0,
    earnGamePasses: 0,
    isLoading: true,
    referralLink: "",
};

export const referralSlice = createSlice({
    initialState,
    name: "referral",
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getReferralData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getReferralData.fulfilled, (state, action) => {
                const { payload } = action;
                state.code = payload.code;
                state.count = Number(payload.count);
                state.earn = Number(payload.earn);
                state.earnGamePasses = Number(payload.earnGamePasses);
                state.referralLink = `${import.meta.env
                    .VITE_APP_APP_LINK!}?startapp=ref-${payload.code}`;
                state.isLoading = false;
            })
            .addCase(getReferralData.rejected, (state, action) => {
                state.isLoading = false;
                const { error } = action;
                // eslint-disable-next-line no-console
                console.error(error.message);
            });
    },
});

// export const actions = referralSlice.actions;

export default referralSlice.reducer;
