import { createAsyncThunk } from "@reduxjs/toolkit";

import { ILimitOffset } from "@services/endpoints/earn/Frens";
import { LeaderBoardsAPI } from "@services/endpoints/earn/LeaderBoards";

export const getLeaderBoardsData = createAsyncThunk(
    "referral/leaderBoard/list/",
    async ({ limit, offset }: ILimitOffset) => {
        const response = await LeaderBoardsAPI.getLeaderBoardsData({
            limit,
            offset,
        });
        return response.data;
    }
);
