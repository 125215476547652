import { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { routes } from "@constants/routes";

import { appSelector } from "@redux/app/app.selectors";

import { useAppSelector } from "./useAppSelector";

export const useHandleAppErrors = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const [isUserOffline, setIsUserOffline] = useState(false);

    const { hasServerNotAvailableError, hasSomethingWentWrongError } =
        useAppSelector(appSelector);

    useEffect(() => {
        const handleUserOnline = () => setIsUserOffline(false);
        const handleUserOffline = () => setIsUserOffline(true);

        window.addEventListener("online", handleUserOnline);
        window.addEventListener("offline", handleUserOffline);

        return () => {
            window.removeEventListener("online", handleUserOnline);
            window.removeEventListener("offline", handleUserOffline);
        };
    }, [navigate]);

    useEffect(() => {
        if (hasServerNotAvailableError) {
            navigate(routes.hardLoad);
            return;
        }

        if (hasSomethingWentWrongError || isUserOffline) {
            navigate(routes.oops);
            return;
        }

        const isOopsScreen = pathname === routes.oops;
        const isHardLoadScreen = pathname === routes.hardLoad;
        const isErrorScreen = isOopsScreen || isHardLoadScreen;

        if (
            !hasServerNotAvailableError &&
            !hasSomethingWentWrongError &&
            isErrorScreen
        ) {
            navigate(routes.play);
        }
    }, [
        hasServerNotAvailableError,
        hasSomethingWentWrongError,
        isUserOffline,
        navigate,
        pathname,
    ]);
};
