import Request, { setToken } from "@services/Request";

import { API_URLS } from "@constants/api";

const url = API_URLS.users;
const urlInfo = API_URLS.userInfo;
const urlGames = API_URLS.games;

export class UserAPI {
    static initToken() {
        // @ts-ignore
        const { initData } = window.Telegram.WebApp;

        const data = Request.post(`${url}`, {
            initData,
        }).then((res) => {
            setToken(res.data.data.token);
            localStorage.setItem("token", res.data.data.token);
        });

        return data;
    }

    static getUserInfo() {
        const data = Request.get(`${urlInfo}`);

        return data;
    }

    static startUserGame() {
        const data = Request.post(`${urlGames}`);

        return data;
    }

    static finishUserGame(id: string) {
        const data = Request.post(`${urlGames}${id}`);

        return data;
    }
}
