/* eslint-disable no-nested-ternary */
import { memo, useEffect, useState } from "react";

import { useAppDispatch } from "@hooks/useAppDispatch";
import { useAppSelector } from "@hooks/useAppSelector";

import Button from "@atoms/Button";
import IconBlock from "@atoms/IconBlock";
import { StandardSunSpinner } from "@atoms/SunSpinner/StandardSunSpinner";
import Typography from "@atoms/Typography";

import InfoCard from "@molecules/InfoCard";
import InfoCardsList from "@molecules/InfoCardsList";
import Modal from "@molecules/Modal";

import MainLayout from "@organisms/layouts/MainLayout";

import DiscordSVG from "@svg/DiscordSVG";
import { FrensSVG } from "@svg/FrensSVG";
import { InstagramSVG } from "@svg/InstagramSVG";
import { TelegramSVG } from "@svg/TelegramSVG";
import { XWebSVG } from "@svg/XWebSVG";

import { earnSelector } from "@redux/earn/earn.selectors";
import { setQuestsList } from "@redux/earn/earn.slice";
import {
    claimEarnQuest,
    getEarnQuests,
    verifyEarnQuest,
} from "@redux/earn/earn.thunk";
import { referralSelector } from "@redux/referral/referral.selectors";
import { getReferralData } from "@redux/referral/referral.thunk";
import { userSelector } from "@redux/users/users.selectors";

import notifications from "@utils/notifications";

import { initUtils } from "@tma.js/sdk";

import style from "./EarnScreen.module.scss";

const icons: any = {
    telegram: <TelegramSVG />,
    twitter: <XWebSVG />,
    instagram: <InstagramSVG />,
    referrals: <FrensSVG />,
    discord: <DiscordSVG />,
};

const utils = initUtils();

const EarnScreen = () => {
    const [currentSubscribe, setSubsribe] = useState<any>();
    const dispatch = useAppDispatch();

    const [quests, setQuests] = useState<any>();
    const [sections, setSections] = useState<any[]>();

    const earns = useAppSelector(earnSelector);
    const { data, isLoading: isEarnsLoading } = earns;

    const { isAuth } = useAppSelector(userSelector);
    const referals = useAppSelector(referralSelector);

    const { referralLink, isLoading: isReferralsLoading } = referals;

    useEffect(() => {
        if (!isAuth) {
            return;
        }

        if (!data) {
            dispatch(getEarnQuests());
        }

        if (!referralLink) {
            dispatch(getReferralData());
        }
    }, [data, dispatch, isAuth, referralLink]);

    useEffect(() => {
        const array: any = [];
        const sectionsArray: any = [];

        earns.data?.data?.map((el: any) => {
            array[el.section] === undefined ? (array[el.section] = []) : false;

            if (!sectionsArray.find((fel: any) => fel === el.section)) {
                sectionsArray.push(el.section);
            }

            array[el.section].push({
                ...el.settings,
                id: el.id,
                blockTitle: el.section,
                status: el.status,
            });
            return el;
        });

        setQuests(array);
        setSections(sectionsArray);
    }, [earns]);

    const handleStartCard = (settings: any, el: any) => {
        if (settings.status === "Start")
            setSubsribe({
                ...settings,
                category: el,
            });
        else if (settings.status === "Claim") {
            dispatch(claimEarnQuest(settings.id)).then((res) => {
                if (res.payload.success) {
                    const { id } = res.payload.data;

                    const filteredQuests = quests[el].map((el: any) => {
                        if (el.id === id) {
                            el.status = "Done";
                        }
                        return el;
                    });

                    quests[el] = filteredQuests;

                    setQuests(quests);
                    dispatch(setQuestsList(quests));
                } else {
                    notifications.createError(res.payload.message);
                }
            });
        }
    };

    const isLoading = isEarnsLoading || isReferralsLoading;

    console.log(13123123);

    return (
        <MainLayout
            additionalScrollHeight={100}
            subtitle={
                <Typography variant="textRegular">
                    Participate in special activities <br /> and get rewards
                </Typography>
            }
            title={<>Earn more points</>}
        >
            <Modal
                open={currentSubscribe !== undefined}
                title={
                    <>
                        {currentSubscribe?.title}
                        <br />
                        {currentSubscribe?.description}
                    </>
                }
                onClose={() => setSubsribe(undefined)}
            >
                <Typography className={style.pointsText} variant="textBold">
                    {currentSubscribe?.header}
                </Typography>
                <Button
                    variant="standard"
                    onClick={() => {
                        dispatch(verifyEarnQuest(currentSubscribe?.id)).then(
                            (res) => {
                                if (res.payload.success) {
                                    const { id } = res.payload.data;

                                    const filteredQuests = quests[
                                        currentSubscribe?.blockTitle
                                    ].map((el: any) => {
                                        if (el.id === id) {
                                            el.status = "Claim";
                                        }
                                        return el;
                                    });

                                    quests[currentSubscribe?.blockTitle] =
                                        filteredQuests;

                                    setQuests(quests);
                                } else {
                                    notifications.createError(
                                        res.payload.message
                                    );
                                }
                            }
                        );

                        if (currentSubscribe?.action === "link") {
                            window.open(currentSubscribe?.link, "blank");
                        } else if (
                            currentSubscribe?.action === "juicy_bet_register"
                        ) {
                            utils.openTelegramLink(currentSubscribe?.link);
                        } else if (currentSubscribe?.action === "follow") {
                            utils.openTelegramLink(currentSubscribe?.channel);
                        }

                        setSubsribe(undefined);
                    }}
                >
                    {currentSubscribe?.buttonTitle}
                </Button>
            </Modal>
            {isLoading ? (
                <StandardSunSpinner />
            ) : (
                sections?.map((el) => {
                    return (
                        <InfoCardsList
                            key={el}
                            className={style.card}
                            title={el}
                        >
                            {quests[el].map((settings: any) => {
                                return (
                                    <InfoCard
                                        key={settings.id}
                                        button={undefined}
                                        buttonProps={{
                                            status: earns.isLoading
                                                ? "loading"
                                                : settings.action ===
                                                  "referrals"
                                                ? "none"
                                                : settings.status.toLowerCase(),
                                            onClick: () =>
                                                handleStartCard(settings, el),
                                            disabled:
                                                settings.action ===
                                                    "referrals" &&
                                                referals.count < 10,
                                            style: {
                                                borderRadius: 16,
                                                height: 44,
                                                minWidth: 60,
                                            },
                                            text:
                                                settings.status === "Claim"
                                                    ? "Claim"
                                                    : settings.status === "Done"
                                                    ? "Done"
                                                    : settings.action ===
                                                      "referrals"
                                                    ? "Claim"
                                                    : "Start",
                                        }}
                                        icon={
                                            <IconBlock
                                                fullSize
                                                borderRadius="big"
                                                mode="orange"
                                            >
                                                {icons[settings.iconType]}
                                            </IconBlock>
                                        }
                                        text={
                                            <>
                                                {settings.title} <br />{" "}
                                                {settings.description}
                                            </>
                                        }
                                        title={settings.header}
                                    />
                                );
                            })}
                        </InfoCardsList>
                    );
                })
            )}
        </MainLayout>
    );
};

export default memo(EarnScreen);
