import { FC } from "react";

import { ISvg } from "./SVG.types";

export const RewardSVG: FC<ISvg> = ({ className }) => {
    return (
        <svg
            className={className}
            fill="none"
            height="80"
            viewBox="0 0 65 80"
            width="65"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.9892 50.1181C17.5275 53.5813 19.7468 57.7531 20.514 61.7728C20.6747 62.6147 19.3829 63.9345 18.5377 63.792C14.5024 63.1113 10.2839 60.9821 6.74556 57.519C3.20721 54.056 0.98791 49.884 0.220695 45.8643C0.0600671 45.0224 1.35183 43.7025 2.19701 43.8451C6.2323 44.5258 10.4508 46.6549 13.9892 50.1181Z"
                fill="url(#paint0_linear_306_1807)"
            />
            <path
                d="M13.9892 50.1181C17.5275 53.5813 19.7468 57.7531 20.514 61.7728C20.6747 62.6147 19.3829 63.9345 18.5377 63.792C14.5024 63.1113 10.2839 60.9821 6.74556 57.519C3.20721 54.056 0.98791 49.884 0.220695 45.8643C0.0600671 45.0224 1.35183 43.7025 2.19701 43.8451C6.2323 44.5258 10.4508 46.6549 13.9892 50.1181Z"
                fill="url(#paint1_linear_306_1807)"
            />
            <path
                d="M18.7875 41.9163C21.6247 47.4611 22.6051 53.3242 21.9143 58.4258C21.7696 59.4942 19.7013 60.5526 18.75 60.0448C14.2084 57.6206 10.0269 53.3954 7.18988 47.8506C4.35286 42.3058 3.37231 36.4428 4.06313 31.3411C4.20776 30.2727 6.27615 29.2144 7.22737 29.7221C11.7689 32.1463 15.9503 36.3715 18.7875 41.9163Z"
                fill="url(#paint2_linear_306_1807)"
            />
            <path
                d="M4.00928 37.4173C4.38418 40.8455 5.4292 44.4091 7.18998 47.8504C10.0272 53.3952 14.2085 57.6204 18.7501 60.0446C19.7012 60.5522 21.7696 59.4938 21.9144 58.4256C22.6052 53.3241 21.6248 47.4609 18.7876 41.9161C17.117 38.651 14.9799 35.8443 12.5779 33.5927L4.00928 37.4173Z"
                fill="url(#paint3_linear_306_1807)"
            />
            <path
                d="M51.0112 50.1181C47.4728 53.5813 45.2535 57.7531 44.4863 61.7728C44.3257 62.6147 45.6175 63.9345 46.4626 63.792C50.4979 63.1113 54.7164 60.9821 58.2548 57.519C61.7931 54.056 64.0124 49.884 64.7797 45.8643C64.9403 45.0224 63.6485 43.7025 62.8033 43.8451C58.7681 44.5258 54.5495 46.6549 51.0112 50.1181Z"
                fill="url(#paint4_linear_306_1807)"
            />
            <path
                d="M51.0112 50.1181C47.4728 53.5813 45.2535 57.7531 44.4863 61.7728C44.3257 62.6147 45.6175 63.9345 46.4626 63.792C50.4979 63.1113 54.7164 60.9821 58.2548 57.519C61.7931 54.056 64.0124 49.884 64.7797 45.8643C64.9403 45.0224 63.6485 43.7025 62.8033 43.8451C58.7681 44.5258 54.5495 46.6549 51.0112 50.1181Z"
                fill="url(#paint5_linear_306_1807)"
            />
            <path
                d="M46.2128 41.9163C43.3757 47.4611 42.3953 53.3242 43.0861 58.4258C43.2307 59.4942 45.2991 60.5526 46.2503 60.0448C50.792 57.6206 54.9735 53.3954 57.8105 47.8506C60.6475 42.3058 61.6281 36.4428 60.9372 31.3411C60.7926 30.2727 58.7242 29.2144 57.773 29.7221C53.2315 32.1463 49.05 36.3715 46.2128 41.9163Z"
                fill="url(#paint6_linear_306_1807)"
            />
            <path
                d="M60.9912 37.4173C60.6163 40.8455 59.5713 44.4091 57.8105 47.8504C54.9733 53.3952 50.792 57.6204 46.2503 60.0446C45.2993 60.5522 43.2309 59.4938 43.0861 58.4256C42.3953 53.3241 43.3757 47.4609 46.2128 41.9161C47.8834 38.651 50.0205 35.8443 52.4226 33.5927L60.9912 37.4173Z"
                fill="url(#paint7_linear_306_1807)"
            />
            <path
                d="M32.4999 79.9998C46.2768 79.9998 57.4452 68.8314 57.4452 55.0545C57.4452 41.2776 46.2768 30.1093 32.4999 30.1093C18.7231 30.1093 7.55469 41.2776 7.55469 55.0545C7.55469 68.8314 18.7231 79.9998 32.4999 79.9998Z"
                fill="url(#paint8_linear_306_1807)"
            />
            <path
                d="M32.4998 74.9375C43.481 74.9375 52.383 66.0356 52.383 55.0544C52.383 44.0732 43.481 35.1713 32.4998 35.1713C21.5187 35.1713 12.6167 44.0732 12.6167 55.0544C12.6167 66.0356 21.5187 74.9375 32.4998 74.9375Z"
                fill="url(#paint9_linear_306_1807)"
            />
            <path
                d="M32.5 72.1245C41.9274 72.1245 49.5698 64.4821 49.5698 55.0547C49.5698 45.6273 41.9274 37.9849 32.5 37.9849C23.0726 37.9849 15.4302 45.6273 15.4302 55.0547C15.4302 64.4821 23.0726 72.1245 32.5 72.1245Z"
                fill="#A780FF"
            />
            <g>
                <path
                    d="M42.7296 49.6347L37.8375 48.4528C37.3825 48.3428 36.9848 48.0669 36.7224 47.6791L33.9023 43.5105C33.5611 43.0063 33.0386 42.7357 32.5009 42.6921C31.9632 42.7357 31.4405 43.0063 31.0995 43.5105L28.2794 47.6791C28.0171 48.0669 27.6195 48.3428 27.1643 48.4528L22.2722 49.6347C20.9451 49.9554 20.3922 51.5392 21.2311 52.6162L24.3243 56.5864C24.612 56.9558 24.7516 57.4192 24.7155 57.886L24.3279 62.9039C24.2228 64.265 25.5582 65.2805 26.8418 64.8153L31.5735 63.1005C31.8725 62.9921 32.1914 62.9641 32.5009 63.0121C32.8106 62.9643 33.1293 62.9922 33.4283 63.1005L38.16 64.8153C39.4435 65.2804 40.779 64.265 40.6739 62.9039L40.2863 57.886C40.2502 57.4192 40.3898 56.9558 40.6775 56.5864L43.7707 52.6162C44.6096 51.5392 44.0567 49.9554 42.7296 49.6347Z"
                    fill="url(#paint10_linear_306_1807)"
                />
                <path
                    d="M33.4279 63.1005L38.1596 64.8153C39.4431 65.2804 40.7786 64.265 40.6735 62.9039L40.2859 57.886C40.2498 57.4192 40.3894 56.9558 40.6771 56.5864L43.7703 52.6162C44.6094 51.5394 44.0563 49.9554 42.7292 49.6347L37.8371 48.4528C37.3821 48.3428 36.9844 48.0669 36.722 47.6791L33.9019 43.5105C33.5607 43.0063 33.0382 42.7357 32.5005 42.6921V63.0121C32.8101 62.9641 33.129 62.9921 33.4279 63.1005Z"
                    fill="url(#paint11_linear_306_1807)"
                />
                <path
                    d="M42.7293 49.6351L37.8372 48.4531C37.6701 48.4128 37.5111 48.3496 37.3635 48.2672L24.8926 64.3979C25.3818 64.8707 26.1199 65.0773 26.8414 64.8158L31.5732 63.1009C31.8722 62.9925 32.1911 62.9646 32.5006 63.0126C32.8102 62.9648 33.129 62.9927 33.4279 63.1009L38.1597 64.8158C39.4432 65.2809 40.7787 64.2655 40.6736 62.9044L40.286 57.8865C40.2499 57.4197 40.3895 56.9563 40.6772 56.5869L43.7704 52.6167C44.6093 51.5395 44.0563 49.9557 42.7293 49.6351Z"
                    fill="url(#paint12_linear_306_1807)"
                />
                <path
                    d="M22.2722 49.6352L27.1643 48.4532C27.3313 48.4129 27.4904 48.3497 27.638 48.2673L40.1089 64.398C39.6197 64.8708 38.8816 65.0774 38.16 64.8159L33.4283 63.1011C33.1293 62.9927 32.8104 62.9647 32.5009 63.0127C32.1913 62.9649 31.8725 62.9928 31.5735 63.1011L26.8418 64.8159C25.5583 65.281 24.2228 64.2656 24.3279 62.9045L24.7155 57.8866C24.7516 57.4198 24.612 56.9564 24.3243 56.587L21.2311 52.6168C20.3922 51.5396 20.9451 49.9558 22.2722 49.6352Z"
                    fill="url(#paint13_linear_306_1807)"
                />
                <path
                    d="M20.9705 50.7683C20.7379 51.355 20.7938 52.0541 21.2316 52.6162L24.3247 56.5863C24.6124 56.9558 24.752 57.4191 24.716 57.886L24.3284 62.9038C24.2233 64.2649 25.5586 65.2805 26.8422 64.8152L31.574 63.1004C31.873 62.992 32.1919 62.9641 32.5014 63.0121C32.811 62.9642 33.1298 62.9922 33.4287 63.1004L38.1605 64.8152C39.444 65.2803 40.7795 64.2649 40.6744 62.9038L40.2868 57.886C40.2667 57.627 40.3026 57.3696 40.3861 57.1282L20.9705 50.7683Z"
                    fill="url(#paint14_linear_306_1807)"
                />
                <path
                    d="M44.0334 50.7683C44.266 51.355 44.2103 52.0541 43.7724 52.6162L40.6792 56.5863C40.3915 56.9558 40.2519 57.4191 40.288 57.886L40.6756 62.9038C40.7807 64.2649 39.4452 65.2805 38.1617 64.8152L33.43 63.1004C33.131 62.992 32.8121 62.9641 32.5026 63.0121C32.1929 62.9642 31.8742 62.9922 31.5752 63.1004L26.8434 64.8152C25.56 65.2803 24.2245 64.2649 24.3296 62.9038L24.7172 57.886C24.7372 57.627 24.7013 57.3696 24.6179 57.1282L44.0334 50.7683Z"
                    fill="url(#paint15_linear_306_1807)"
                />
            </g>
            <path
                d="M17.4409 0.522705V13.6992L32.4997 28.758L47.5586 13.6992V0.522705H17.4409Z"
                fill="url(#paint16_linear_306_1807)"
            />
            <path
                d="M17.4409 0.522705V4.62341L32.4494 19.6319C32.4772 19.6596 32.5223 19.6596 32.5501 19.6319L47.5586 4.62341V0.522705H17.4409Z"
                fill="url(#paint17_linear_306_1807)"
            />
            <path
                d="M32.4997 22.0049C31.8789 22.0049 31.2582 21.7687 30.7857 21.2961L17.4409 7.95129V13.6997L32.4997 28.7585L47.5586 13.6997V7.95129L34.2138 21.2961C33.7413 21.7685 33.1206 22.0049 32.4997 22.0049Z"
                fill="url(#paint18_linear_306_1807)"
            />
            <path
                d="M32.4997 22.0049C31.8789 22.0049 31.2582 21.7687 30.7857 21.2961L17.4409 7.95129V13.6997L32.4997 28.7585L47.5586 13.6997V7.95129L34.2138 21.2961C33.7413 21.7685 33.1206 22.0049 32.4997 22.0049Z"
                fill="url(#paint19_linear_306_1807)"
            />
            <path
                d="M30.8489 25.3375L18.0342 12.5227C17.0733 11.5618 15.5151 11.5618 14.5542 12.5227C13.5933 13.4836 13.5933 15.0418 14.5542 16.0027L32.5002 33.9491L50.4464 16.0029C51.4074 15.0419 51.4074 13.4838 50.4464 12.5229C49.4855 11.5619 47.9274 11.5619 46.9664 12.5229L34.1517 25.3376C33.2395 26.2496 31.7609 26.2496 30.8489 25.3375Z"
                fill="url(#paint20_linear_306_1807)"
            />
            <path
                d="M14.554 12.5519L30.6107 28.6085C31.6543 29.6521 33.3464 29.6521 34.39 28.6085L50.4464 12.5519C50.4511 12.5472 50.4549 12.542 50.4596 12.5373C50.4551 12.5326 50.4511 12.5274 50.4464 12.5227C49.4855 11.5618 47.9274 11.5618 46.9664 12.5227L34.1517 25.3375C33.2397 26.2495 31.7609 26.2495 30.8489 25.3375L18.0342 12.5227C17.0733 11.5618 15.5151 11.5618 14.5542 12.5227C14.5495 12.5274 14.5457 12.5326 14.541 12.5373C14.5456 12.542 14.5493 12.5472 14.554 12.5519Z"
                fill="url(#paint21_linear_306_1807)"
            />
            <path
                d="M32.5002 35.6853C35.7488 35.6853 38.3822 33.0519 38.3822 29.8033C38.3822 26.5547 35.7488 23.9213 32.5002 23.9213C29.2516 23.9213 26.6182 26.5547 26.6182 29.8033C26.6182 33.0519 29.2516 35.6853 32.5002 35.6853Z"
                fill="url(#paint22_linear_306_1807)"
            />
            <path
                d="M32.5002 33.1756C34.3626 33.1756 35.8724 31.6658 35.8724 29.8034C35.8724 27.941 34.3626 26.4312 32.5002 26.4312C30.6377 26.4312 29.1279 27.941 29.1279 29.8034C29.1279 31.6658 30.6377 33.1756 32.5002 33.1756Z"
                fill="url(#paint23_linear_306_1807)"
            />
            <path
                d="M49.8337 5.85616H15.1664C14.1703 5.85616 13.3628 5.04863 13.3628 4.05255V1.80361C13.3628 0.807529 14.1703 0 15.1664 0H49.8339C50.8298 0 51.6373 0.807529 51.6373 1.80361V4.05271C51.6373 5.04878 50.8298 5.85616 49.8337 5.85616Z"
                fill="url(#paint24_linear_306_1807)"
            />
            <defs>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint0_linear_306_1807"
                    x1="10.8693"
                    x2="10.5931"
                    y1="48.2396"
                    y2="56.5499"
                >
                    <stop stopColor="#A880FF" />
                    <stop offset="1" stopColor="#FF7425" />
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint1_linear_306_1807"
                    x1="10.8693"
                    x2="10.5931"
                    y1="48.2396"
                    y2="56.5499"
                >
                    <stop stopColor="#A880FF" />
                    <stop offset="1" stopColor="#FF7425" />
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint2_linear_306_1807"
                    x1="13.4402"
                    x2="12.7213"
                    y1="36.3382"
                    y2="49.0403"
                >
                    <stop stopColor="#A880FF" />
                    <stop offset="1" stopColor="#FF7425" />
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint3_linear_306_1807"
                    x1="13.5156"
                    x2="12.9674"
                    y1="39.457"
                    y2="50.5052"
                >
                    <stop stopColor="#A880FF" />
                    <stop offset="1" stopColor="#FF7425" />
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint4_linear_306_1807"
                    x1="55.1349"
                    x2="54.8588"
                    y1="48.2396"
                    y2="56.5499"
                >
                    <stop stopColor="#A880FF" />
                    <stop offset="1" stopColor="#FF7425" />
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint5_linear_306_1807"
                    x1="55.1349"
                    x2="54.8588"
                    y1="48.2396"
                    y2="56.5499"
                >
                    <stop stopColor="#A880FF" />
                    <stop offset="1" stopColor="#FF7425" />
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint6_linear_306_1807"
                    x1="52.4631"
                    x2="51.7442"
                    y1="36.3382"
                    y2="49.0403"
                >
                    <stop stopColor="#A880FF" />
                    <stop offset="1" stopColor="#FF7425" />
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint7_linear_306_1807"
                    x1="52.3799"
                    x2="51.8317"
                    y1="39.457"
                    y2="50.5052"
                >
                    <stop stopColor="#A880FF" />
                    <stop offset="1" stopColor="#FF7425" />
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint8_linear_306_1807"
                    x1="11.7122"
                    x2="57.4452"
                    y1="62.1817"
                    y2="62.1817"
                >
                    <stop stopColor="#FF8F4D" />
                    <stop offset="1" stopColor="#582EFF" />
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint9_linear_306_1807"
                    x1="15.9306"
                    x2="52.383"
                    y1="60.7353"
                    y2="60.7353"
                >
                    <stop stopColor="#FFB488" />
                    <stop offset="1" stopColor="#392390" />
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint10_linear_306_1807"
                    x1="27.3047"
                    x2="36.675"
                    y1="50.3136"
                    y2="59.6838"
                >
                    <stop stopColor="#FBC56D" />
                    <stop offset="1" stopColor="#C5715A" />
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint11_linear_306_1807"
                    x1="38.1151"
                    x2="25.164"
                    y1="52.8685"
                    y2="58.2225"
                >
                    <stop stopColor="#8C3D27" stopOpacity="0" />
                    <stop offset="1" stopColor="#8C3D27" />
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint12_linear_306_1807"
                    x1="34.7337"
                    x2="27.6432"
                    y1="57.4649"
                    y2="54.426"
                >
                    <stop stopColor="#8C3D27" stopOpacity="0" />
                    <stop offset="1" stopColor="#8C3D27" />
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint13_linear_306_1807"
                    x1="30.2678"
                    x2="37.3583"
                    y1="57.465"
                    y2="54.4261"
                >
                    <stop stopColor="#8C3D27" stopOpacity="0" />
                    <stop offset="1" stopColor="#8C3D27" />
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint14_linear_306_1807"
                    x1="30.4259"
                    x2="34.984"
                    y1="57.8201"
                    y2="50.4944"
                >
                    <stop stopColor="#8C3D27" stopOpacity="0" />
                    <stop offset="1" stopColor="#8C3D27" />
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint15_linear_306_1807"
                    x1="34.2267"
                    x2="33.8469"
                    y1="56.8949"
                    y2="49.7321"
                >
                    <stop stopColor="#8C3D27" stopOpacity="0" />
                    <stop offset="1" stopColor="#8C3D27" />
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint16_linear_306_1807"
                    x1="33.6154"
                    x2="39.8114"
                    y1="8.89024"
                    y2="18.7726"
                >
                    <stop stopColor="#F9F5F3" />
                    <stop offset="1" stopColor="#D8E8F0" />
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint17_linear_306_1807"
                    x1="32.4997"
                    x2="32.4997"
                    y1="0.522705"
                    y2="19.6527"
                >
                    <stop stopColor="#7568FF" />
                    <stop offset="1" stopColor="#582EFF" />
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint18_linear_306_1807"
                    x1="23.3476"
                    x2="42.5893"
                    y1="0.330275"
                    y2="22.3955"
                >
                    <stop stopColor="#BBEC6C" />
                    <stop offset="1" stopColor="#81B823" />
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint19_linear_306_1807"
                    x1="32.4997"
                    x2="32.4997"
                    y1="7.95129"
                    y2="28.7585"
                >
                    <stop stopColor="#7568FF" />
                    <stop offset="1" stopColor="#582EFF" />
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint20_linear_306_1807"
                    x1="32.5002"
                    x2="32.5002"
                    y1="13.0717"
                    y2="40.7308"
                >
                    <stop stopColor="#FF817D" />
                    <stop offset="1" stopColor="#A14B93" />
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint21_linear_306_1807"
                    x1="32.5002"
                    x2="32.5002"
                    y1="12.8104"
                    y2="34.7773"
                >
                    <stop stopColor="#FF817D" />
                    <stop offset="1" stopColor="#A14B93" />
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint22_linear_306_1807"
                    x1="29.1277"
                    x2="37.4461"
                    y1="26.4308"
                    y2="34.7492"
                >
                    <stop stopColor="#FBC56D" />
                    <stop offset="1" stopColor="#C5715A" />
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint23_linear_306_1807"
                    x1="34.8923"
                    x2="30.7745"
                    y1="32.1955"
                    y2="28.0777"
                >
                    <stop stopColor="#FBC56D" />
                    <stop offset="1" stopColor="#C5715A" />
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint24_linear_306_1807"
                    x1="32.5"
                    x2="32.5"
                    y1="0.335734"
                    y2="7.6494"
                >
                    <stop stopColor="#FF817D" />
                    <stop offset="1" stopColor="#A14B93" />
                </linearGradient>
            </defs>
        </svg>
    );
};
