import { FC } from "react";

import { ISvg } from "./SVG.types";

export const PointsSVG: FC<ISvg> = ({ className }) => {
    return (
        <svg
            className={className}
            fill="none"
            height="38"
            viewBox="0 0 32 38"
            width="32"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16 38C24.8366 38 32 30.8366 32 22C32 13.1634 24.8366 6 16 6C7.16345 6 0 13.1634 0 22C0 30.8366 7.16345 38 16 38Z"
                fill="url(#paint0_linear_413_1932)"
            />
            <path
                d="M15.9983 34.7508C23.0416 34.7508 28.7514 29.041 28.7514 21.9976C28.7514 14.9543 23.0416 9.24451 15.9983 9.24451C8.95489 9.24451 3.24512 14.9543 3.24512 21.9976C3.24512 29.041 8.95489 34.7508 15.9983 34.7508Z"
                fill="url(#paint1_linear_413_1932)"
            />
            <path
                d="M15.9906 32.9497C22.0374 32.9497 26.9393 28.0478 26.9393 22.001C26.9393 15.9542 22.0374 11.0524 15.9906 11.0524C9.94387 11.0524 5.04199 15.9542 5.04199 22.001C5.04199 28.0478 9.94387 32.9497 15.9906 32.9497Z"
                fill="#A780FF"
            />
            <g>
                <path
                    d="M22.5709 18.5287L19.4331 17.7706C19.1413 17.7 18.8862 17.5231 18.7179 17.2744L16.9091 14.6006C16.6902 14.2772 16.3551 14.1037 16.0102 14.0757C15.6653 14.1037 15.3301 14.2772 15.1113 14.6006L13.3025 17.2744C13.1343 17.5231 12.8793 17.7 12.5873 17.7706L9.44947 18.5287C8.59829 18.7343 8.24363 19.7502 8.78171 20.441L10.7657 22.9875C10.9502 23.2245 11.0398 23.5217 11.0166 23.8211L10.768 27.0396C10.7006 27.9126 11.5571 28.564 12.3804 28.2656L15.4154 27.1657C15.6072 27.0962 15.8117 27.0782 16.0102 27.109C16.2088 27.0783 16.4133 27.0963 16.605 27.1657L19.64 28.2656C20.4632 28.5639 21.3198 27.9126 21.2524 27.0396L21.0038 23.8211C20.9807 23.5217 21.0702 23.2245 21.2547 22.9875L23.2387 20.441C23.7768 19.7502 23.4221 18.7343 22.5709 18.5287Z"
                    fill="url(#paint2_linear_413_1932)"
                />
                <path
                    d="M16.6026 27.1657L19.6376 28.2656C20.4608 28.5639 21.3174 27.9126 21.25 27.0396L21.0014 23.8211C20.9783 23.5217 21.0678 23.2245 21.2523 22.9875L23.2363 20.441C23.7745 19.7503 23.4197 18.7343 22.5686 18.5287L19.4307 17.7706C19.1389 17.7 18.8838 17.5231 18.7155 17.2744L16.9067 14.6006C16.6879 14.2772 16.3527 14.1037 16.0078 14.0757V27.109C16.2064 27.0782 16.411 27.0961 16.6026 27.1657Z"
                    fill="url(#paint3_linear_413_1932)"
                />
                <path
                    d="M22.5724 18.528L19.4346 17.7699C19.3274 17.744 19.2254 17.7035 19.1307 17.6506L11.1318 27.9969C11.4456 28.3002 11.919 28.4327 12.3818 28.265L15.4168 27.1651C15.6086 27.0955 15.8131 27.0776 16.0116 27.1084C16.2103 27.0777 16.4147 27.0956 16.6065 27.1651L19.6414 28.265C20.4647 28.5633 21.3213 27.912 21.2539 27.039L21.0052 23.8205C20.9821 23.5211 21.0716 23.2239 21.2562 22.9869L23.2402 20.4404C23.7782 19.7495 23.4236 18.7336 22.5724 18.528Z"
                    fill="url(#paint4_linear_413_1932)"
                />
                <path
                    d="M9.44947 18.528L12.5873 17.7699C12.6944 17.744 12.7964 17.7035 12.8911 17.6506L20.89 27.9969C20.5762 28.3002 20.1028 28.4327 19.64 28.265L16.605 27.1651C16.4133 27.0955 16.2087 27.0776 16.0102 27.1084C15.8116 27.0777 15.6072 27.0956 15.4154 27.1651L12.3804 28.265C11.5572 28.5633 10.7006 27.912 10.768 27.039L11.0166 23.8205C11.0398 23.5211 10.9502 23.2239 10.7657 22.9869L8.78171 20.4404C8.24363 19.7495 8.59829 18.7336 9.44947 18.528Z"
                    fill="url(#paint5_linear_413_1932)"
                />
                <path
                    d="M8.61497 19.2539C8.46577 19.6302 8.50158 20.0786 8.78239 20.4391L10.7664 22.9856C10.9509 23.2226 11.0404 23.5198 11.0173 23.8192L10.7687 27.0377C10.7013 27.9107 11.5578 28.5621 12.3811 28.2637L15.4161 27.1638C15.6078 27.0942 15.8124 27.0763 16.0109 27.1071C16.2095 27.0764 16.414 27.0943 16.6057 27.1638L19.6407 28.2637C20.4639 28.562 21.3205 27.9107 21.2531 27.0377L21.0045 23.8192C20.9916 23.6531 21.0147 23.488 21.0682 23.3331L8.61497 19.2539Z"
                    fill="url(#paint6_linear_413_1932)"
                />
                <path
                    d="M23.3997 19.2538C23.5489 19.6301 23.5132 20.0785 23.2323 20.439L21.2483 22.9855C21.0638 23.2224 20.9742 23.5197 20.9974 23.8191L21.246 27.0376C21.3134 27.9106 20.4568 28.5619 19.6336 28.2635L16.5986 27.1636C16.4068 27.0941 16.2023 27.0762 16.0038 27.107C15.8052 27.0763 15.6007 27.0942 15.4089 27.1636L12.374 28.2635C11.5508 28.5618 10.6941 27.9106 10.7615 27.0376L11.0102 23.8191C11.023 23.653 11 23.4879 10.9465 23.333L23.3997 19.2538Z"
                    fill="url(#paint7_linear_413_1932)"
                />
            </g>
            <defs>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint0_linear_413_1932"
                    x1="2.66667"
                    x2="32"
                    y1="26.5714"
                    y2="26.5714"
                >
                    <stop stopColor="#FF8F4D" />
                    <stop offset="1" stopColor="#582EFF" />
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint1_linear_413_1932"
                    x1="5.37064"
                    x2="28.7514"
                    y1="25.6414"
                    y2="25.6414"
                >
                    <stop stopColor="#FFB488" />
                    <stop offset="1" stopColor="#392390" />
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint2_linear_413_1932"
                    x1="12.6773"
                    x2="18.6875"
                    y1="18.9641"
                    y2="24.9742"
                >
                    <stop stopColor="#FBC56D" />
                    <stop offset="1" stopColor="#C5715A" />
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint3_linear_413_1932"
                    x1="19.609"
                    x2="11.3022"
                    y1="20.6028"
                    y2="24.0369"
                >
                    <stop stopColor="#8C3D27" stopOpacity="0" />
                    <stop offset="1" stopColor="#8C3D27" />
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint4_linear_413_1932"
                    x1="17.444"
                    x2="12.8961"
                    y1="23.55"
                    y2="21.6009"
                >
                    <stop stopColor="#8C3D27" stopOpacity="0" />
                    <stop offset="1" stopColor="#8C3D27" />
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint5_linear_413_1932"
                    x1="14.5779"
                    x2="19.1258"
                    y1="23.55"
                    y2="21.6009"
                >
                    <stop stopColor="#8C3D27" stopOpacity="0" />
                    <stop offset="1" stopColor="#8C3D27" />
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint6_linear_413_1932"
                    x1="14.6797"
                    x2="17.6033"
                    y1="23.7769"
                    y2="19.0782"
                >
                    <stop stopColor="#8C3D27" stopOpacity="0" />
                    <stop offset="1" stopColor="#8C3D27" />
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint7_linear_413_1932"
                    x1="17.1096"
                    x2="16.866"
                    y1="23.1834"
                    y2="18.5891"
                >
                    <stop stopColor="#8C3D27" stopOpacity="0" />
                    <stop offset="1" stopColor="#8C3D27" />
                </linearGradient>
            </defs>
        </svg>
    );
};
