import { useNavigate } from "react-router-dom";

import { routes } from "@constants/routes";

import Button from "@atoms/Button";
import IconBlock from "@atoms/IconBlock";
import Typography from "@atoms/Typography";
import WindowBlock from "@atoms/WindowBlock";

import MainLayout from "@organisms/layouts/MainLayout";

import EffectsOrangeSVG from "@svg/EffectsOrangeSVG";
import FrensOrangeSVG from "@svg/FrensOrangeSVG";
import PlayOrangeSVG from "@svg/PlayOrangeSVG";
import ShelfOrangeSVG from "@svg/ShelfOrangeSVG";

import style from "./WelcomeScreen.module.scss";

const welcomeData = [
    {
        icon: <PlayOrangeSVG />,
        title: <> Play zone </>,
        description: (
            <>
                Play the Tap rush game to get points with new game passes every
                8 hours. <br /> Tap to keep the ball in the air, maintain
                intensity as it rises to expend more energy, and aim to achieve
                a high score before it bursts.
            </>
        ),
    },
    {
        icon: <EffectsOrangeSVG />,
        title: <> Earn zone </>,
        description: <>Participate in special activities to get more points</>,
    },
    {
        icon: <FrensOrangeSVG />,
        title: <> Frens zone </>,
        description: (
            <>
                Invite frens to score 10% from your boddies and get extra game
                passes
            </>
        ),
    },
    {
        icon: <ShelfOrangeSVG />,
        title: <> Shelf zone </>,
        description: (
            <>
                Get extra game passes and perks from the lootboxes for your
                rewardeed points
            </>
        ),
    },
];

const WelcomeScreen = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        localStorage.setItem("firstTime", "1");

        navigate(routes.play);
    };

    return (
        <MainLayout hasNavbar={false} title="Welcome to Tap rush">
            <div className={style.welcomeContainer}>
                {welcomeData.map((el, index) => {
                    return (
                        <WindowBlock key={index} className={style.block}>
                            <div className={style.titleBlock}>
                                <IconBlock
                                    className={style.iconBlock}
                                    mode="black"
                                >
                                    {el.icon}
                                </IconBlock>
                                <Typography variant="textBold">
                                    {el.title}
                                </Typography>
                            </div>
                            <Typography variant="text2Regular">
                                {el.description}
                            </Typography>
                        </WindowBlock>
                    );
                })}
            </div>
            <Button
                style={{ marginTop: 30 }}
                variant="gradient"
                onClick={handleClick}
            >
                Start now
            </Button>
        </MainLayout>
    );
};

export default WelcomeScreen;
