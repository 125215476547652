import { FC, memo } from "react";

import { useNavigate } from "react-router-dom";

import Button from "@atoms/Button";
import Typography from "@atoms/Typography";

import { ArrowRightSVG } from "@svg/ArrowRigthSVG";

import styles from "./Header.module.scss";
import { IHeader } from "./Header.types";

const Header: FC<IHeader> = ({ title, subtitle, withGoBack, navigateURL }) => {
    const navigate = useNavigate();

    return (
        <header className={styles.wrapper}>
            {withGoBack && (
                <Button
                    autoSize
                    className={styles.back}
                    onClick={() =>
                        navigateURL ? navigate(navigateURL) : navigate(-1)
                    }
                >
                    <ArrowRightSVG
                        className={styles.icon}
                        height={24}
                        width={24}
                    />
                </Button>
            )}

            <Typography className={styles.title} variant="h1Bold">
                {title}
            </Typography>
            {subtitle && (
                <Typography className={styles.subtitle} variant="textRegular">
                    {subtitle}
                </Typography>
            )}
        </header>
    );
};

export default memo(Header);
