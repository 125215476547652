export const navFilterId = "navFilterId";

export const NavShadowSVG = () => {
    return (
        <svg>
            <defs>
                <filter
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                    height="60"
                    id={navFilterId}
                    width="80"
                    x="-20"
                    xmlns="http://www.w3.org/2000/svg"
                    y="-3"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="10" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 0.6 0 0 0 0 0.431373 0 0 0 0.62 0"
                    />
                    <feBlend
                        in2="BackgroundImageFix"
                        mode="normal"
                        result="effect1_dropShadow_214_17"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.543743 0 0 0 0 0.166028 0 0 0 0 0 0 0 0 0.55 0"
                    />
                    <feBlend
                        in2="effect1_dropShadow_214_17"
                        mode="normal"
                        result="effect2_dropShadow_214_17"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.72291 0 0 0 0 0.214541 0 0 0 0 0 0 0 0 0.34 0"
                    />
                    <feBlend
                        in2="effect2_dropShadow_214_17"
                        mode="normal"
                        result="effect3_dropShadow_214_17"
                    />
                    <feBlend
                        in="SourceGraphic"
                        in2="effect3_dropShadow_214_17"
                        mode="normal"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
};
