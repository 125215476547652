import { FC } from "react";

import { ISvg } from "./SVG.types";

export const SuccessSVG: FC<ISvg> = (props) => {
    return (
        <svg
            fill="none"
            height="32"
            viewBox="0 0 32 32"
            width="32"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M21.53 12.47C21.6705 12.6106 21.7493 12.8012 21.7493 13C21.7493 13.1988 21.6705 13.3894 21.53 13.53L14.53 20.53C14.3894 20.6705 14.1988 20.7493 14 20.7493C13.8013 20.7493 13.6106 20.6705 13.47 20.53L10.47 17.53C10.3375 17.3878 10.2654 17.1998 10.2688 17.0055C10.2723 16.8112 10.351 16.6258 10.4884 16.4884C10.6258 16.351 10.8112 16.2723 11.0055 16.2688C11.1998 16.2654 11.3878 16.3375 11.53 16.47L14 18.9387L20.47 12.47C20.6106 12.3295 20.8013 12.2507 21 12.2507C21.1988 12.2507 21.3894 12.3295 21.53 12.47ZM28.75 16C28.75 18.5217 28.0022 20.9868 26.6012 23.0835C25.2003 25.1802 23.209 26.8144 20.8792 27.7795C18.5495 28.7445 15.9859 28.997 13.5126 28.505C11.0393 28.0131 8.76751 26.7987 6.98439 25.0156C5.20127 23.2325 3.98696 20.9607 3.49499 18.4874C3.00303 16.0141 3.25552 13.4505 4.22054 11.1208C5.18556 8.79103 6.81976 6.79975 8.91648 5.39876C11.0132 3.99777 13.4783 3.25 16 3.25C19.3803 3.25397 22.621 4.59854 25.0112 6.98877C27.4015 9.379 28.746 12.6197 28.75 16ZM27.25 16C27.25 13.775 26.5902 11.5999 25.354 9.74984C24.1179 7.89978 22.3609 6.45784 20.3052 5.60636C18.2495 4.75487 15.9875 4.53208 13.8052 4.96617C11.623 5.40025 9.6184 6.47171 8.04505 8.04505C6.47171 9.61839 5.40025 11.6229 4.96617 13.8052C4.53209 15.9875 4.75488 18.2495 5.60636 20.3052C6.45785 22.3609 7.89979 24.1179 9.74984 25.354C11.5999 26.5902 13.775 27.25 16 27.25C18.9827 27.2467 21.8422 26.0604 23.9513 23.9513C26.0604 21.8422 27.2467 18.9827 27.25 16Z"
                fill="#FF8C63"
            />
        </svg>
    );
};
