import { AUTO, Game, Scale, Types } from "phaser";

import { SpinePlugin } from "@esotericsoftware/spine-phaser";

import { Game as MainGame } from "../../../scenes/Game";
import { PreloadScene } from "../../../scenes/PreloaderScene";

const config: Types.Core.GameConfig = {
    backgroundColor: "#4e92fe",
    height: "100%",
    parent: "game-container",
    physics: {
        arcade: {
            debug: false,
            gravity: { x: 0, y: 0 },
        },
        default: "arcade",
    },
    plugins: {
        scene: [{ key: "SpinePlugin", mapping: "spine", plugin: SpinePlugin }],
    },
    scene: [PreloadScene, MainGame],
    type: AUTO,
    width: "100%",
    scale: {
        mode: Scale.RESIZE,
        // autoCenter: Scale.CENTER_BOTH,
        width: "100%",
        height: "100%",
    },
};

const StartGame = (parent: any) => {
    return new Game({ ...config, parent });
};

export default StartGame;
