import { useEffect, useState } from "react";

import clsx from "clsx";
import { createPortal } from "react-dom";

import Button from "@atoms/Button";
import Typography from "@atoms/Typography";

import { CloseSvg } from "@svg/CloseSVG";

import { on } from "@tma.js/sdk";

import styles from "./Modal.module.scss";
import { IModal } from "./Modal.types";

const Modal: FCC<IModal> = ({
    onClose,
    open,
    children,
    title,
    titleClassName,
    className,
    windowClassName,
}) => {
    const [orientation, setOrientation] = useState<null | string>(null);

    useEffect(() => {
        // Start listening to "viewport_changed" event. Returned value
        // is a function, which removes this event listener.
        setOrientation(
            window.innerWidth > window.innerHeight ? "landscape" : "portrait"
        );

        const removeListener = on("viewport_changed", (payload) => {
            setOrientation(
                payload.width > payload.height ? "landscape" : "portrait"
            );
        });

        return () => {
            // Remove this event listener.
            removeListener();
        };
    }, []);

    return createPortal(
        <div
            className={clsx(styles.wrapper, className, {
                [styles.wrapper_open]: open,
            })}
        >
            <div
                className={clsx(styles.bg, {
                    [styles.bg_open]: open,
                })}
            />
            <div
                className={clsx(styles.window, windowClassName, {
                    [styles.window_open]: open,
                    [styles.overflow_auto]: orientation === "landscape",
                })}
            >
                <div className={styles.body}>
                    <Button autoSize className={styles.close} onClick={onClose}>
                        <CloseSvg />
                    </Button>
                    <Typography
                        className={clsx(styles.title, titleClassName)}
                        variant="textRegular"
                    >
                        {title}
                    </Typography>

                    <div className={styles.content}>{children}</div>
                </div>
            </div>
        </div>,
        document.getElementsByTagName("body")[0]
    );
};

export default Modal;
