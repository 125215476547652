import { useEffect } from "react";

import { Route, Routes, useNavigate } from "react-router-dom";

import { routes } from "@constants/routes";

import { useAppDispatch } from "@hooks/useAppDispatch";
import { useAppSelector } from "@hooks/useAppSelector";
import { useHandleAppErrors } from "@hooks/useHadnleAppErrors";

import { PhaserGame } from "@molecules/PhaserGame/PhaserGame";
import ToasterComponent from "@molecules/ToasterComponent/ToasterComponent";

import CheckResultsScreen from "@screens/CheckResultsScreen/CheckResultsScreen";
import EarnScreen from "@screens/EarnScreen/EarnScreen";
import FrensScreen from "@screens/FrensScreen";
import HardLoadScreen from "@screens/HardLoadScreen/HardLoadScreen";
import LeaderBoardScreen from "@screens/LeaderBoardScreen";
import LoadingScreen from "@screens/LoadingScreen";
import OopsScreen from "@screens/OopsScreen/OopsScreen";
import PlayScreen from "@screens/PlayScreen/PlayScreen";
import ShelfScreen from "@screens/ShelfScreen";
import WelcomeScreen from "@screens/WelcomeScreen/WelcomeScreen";
import YourPerksScreen from "@screens/YourPerksScreen";

import { userSelector } from "@redux/users/users.selectors";
import { getUserInfo, initUserToken } from "@redux/users/users.thunk";

import { postEvent } from "@tma.js/sdk";

import "./App.css";

const App = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { userData, isAuth } = useAppSelector(userSelector);

    useEffect(() => {
        dispatch(initUserToken());
    }, [dispatch]);

    useEffect(() => {
        const firstTime = localStorage.getItem("firstTime");

        if (Number(firstTime) === null || Number(firstTime) === 0) {
            navigate(routes.welcome);
        }
    }, [navigate]);

    useEffect(() => {
        if (isAuth && !userData) {
            dispatch(getUserInfo());
        }
    }, [dispatch, isAuth, userData]);

    useHandleAppErrors();

    useEffect(() => {
        postEvent("web_app_expand", {});
        postEvent("web_app_setup_closing_behavior", {
            need_confirmation: false,
        });
    }, []);

    return (
        <>
            <ToasterComponent />
            <Routes>
                {isAuth && (
                    <>
                        <Route element={<PlayScreen />} path={routes.play} />
                        <Route element={<EarnScreen />} path={routes.earn} />
                        <Route element={<FrensScreen />} path={routes.frens} />
                        <Route
                            element={<LeaderBoardScreen />}
                            path={routes.leaderboard}
                        />
                        <Route element={<ShelfScreen />} path={routes.shelf} />
                        <Route
                            element={<YourPerksScreen />}
                            path={routes.perks}
                        />
                        <Route element={<FrensScreen />} path={routes.shelf} />

                        <Route element={<PhaserGame />} path={routes.game} />

                        <Route
                            element={<CheckResultsScreen />}
                            path={routes.results}
                        />
                    </>
                )}

                <Route element={<WelcomeScreen />} path={routes.welcome} />
                <Route element={<OopsScreen />} path={routes.oops} />
                <Route element={<HardLoadScreen />} path={routes.hardLoad} />

                <Route element={<LoadingScreen />} path="*" />
            </Routes>
        </>
    );
};

export default App;
