import { FC } from "react";

import { ISvg } from "./SVG.types";

export const ChartSVG: FC<ISvg> = (props) => {
    return (
        <svg
            fill="none"
            height="12"
            viewBox="0 0 12 12"
            width="12"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M10.7812 9.75C10.7812 9.82459 10.7516 9.89613 10.6989 9.94887C10.6461 10.0016 10.5746 10.0312 10.5 10.0312H1.5C1.42541 10.0312 1.35387 10.0016 1.30113 9.94887C1.24838 9.89613 1.21875 9.82459 1.21875 9.75V2.25C1.21875 2.17541 1.24838 2.10387 1.30113 2.05113C1.35387 1.99838 1.42541 1.96875 1.5 1.96875C1.57459 1.96875 1.64613 1.99838 1.69887 2.05113C1.75162 2.10387 1.78125 2.17541 1.78125 2.25V6.88031L4.31484 4.66313C4.36339 4.62071 4.42504 4.59625 4.48947 4.59386C4.5539 4.59148 4.61719 4.61129 4.66875 4.65L7.48641 6.76312L10.3148 4.28813C10.3715 4.24451 10.4426 4.22409 10.5137 4.23101C10.5849 4.23794 10.6507 4.27168 10.6978 4.3254C10.745 4.37912 10.7699 4.44877 10.7676 4.52021C10.7652 4.59165 10.7357 4.65951 10.6852 4.71L7.68516 7.335C7.63661 7.37742 7.57496 7.40187 7.51053 7.40426C7.4461 7.40665 7.38281 7.38683 7.33125 7.34812L4.51359 5.23688L1.78125 7.6275V9.46875H10.5C10.5746 9.46875 10.6461 9.49838 10.6989 9.55113C10.7516 9.60387 10.7812 9.67541 10.7812 9.75Z"
                fill="white"
            />
        </svg>
    );
};
