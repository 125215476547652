import { useCallback, useState } from "react";

export const useManageModal = (initialOpen = false) => {
    const [open, setOpen] = useState(initialOpen);

    const onOpenHandler = useCallback(() => setOpen(true), []);
    const onCloseHandler = useCallback(() => setOpen(false), []);

    return {
        onCloseHandler,
        onOpenHandler,
        open,
    };
};
