import { forwardRef, useEffect, useLayoutEffect, useRef } from "react";

import { useOrientation } from "@hooks/useOrientation";

import BadOrientationScreen from "@screens/BadOrientationScreen/BadOrientationScreen";

import { postEvent } from "@tma.js/sdk";

import { EventBus } from "./EventBus";
import StartGame from "./main";

export interface IRefPhaserGame {
    game: Phaser.Game | null;
    scene: Phaser.Scene | null;
}

interface IProps {
    // eslint-disable-next-line react/require-default-props
    currentActiveScene?: (scene_instance: Phaser.Scene) => void;
}

export const PhaserGame = forwardRef<IRefPhaserGame, IProps>(
    ({ currentActiveScene }, ref) => {
        const orientation = useOrientation();
        const unSupportedOrientation = orientation === "landscape";

        const game = useRef<Phaser.Game | null>(null!);

        useEffect(() => {
            postEvent("web_app_setup_closing_behavior", {
                need_confirmation: true,
            });
        }, []);

        useLayoutEffect(() => {
            if (unSupportedOrientation) return;
            if (game.current === null) {
                game.current = StartGame("game-container");

                if (typeof ref === "function") {
                    ref({ game: game.current, scene: null });
                } else if (ref) {
                    ref.current = { game: game.current, scene: null };
                }
            }

            return () => {
                if (game.current) {
                    game.current.destroy(true);
                    if (game.current !== null) {
                        game.current = null;
                    }
                }
            };
        }, [ref, unSupportedOrientation]);

        useEffect(() => {
            if (unSupportedOrientation) return;
            EventBus.on(
                "current-scene-ready",
                (scene_instance: Phaser.Scene) => {
                    if (
                        currentActiveScene &&
                        typeof currentActiveScene === "function"
                    ) {
                        currentActiveScene(scene_instance);
                    }

                    if (typeof ref === "function") {
                        ref({ game: game.current, scene: scene_instance });
                    } else if (ref) {
                        ref.current = {
                            game: game.current,
                            scene: scene_instance,
                        };
                    }
                }
            );
            return () => {
                EventBus.removeListener("current-scene-ready");
            };
        }, [currentActiveScene, ref, unSupportedOrientation]);

        return (
            <>
                {unSupportedOrientation && <BadOrientationScreen />}
                <div
                    id="game-container"
                    style={{
                        display: unSupportedOrientation ? "none" : "block",
                    }}
                />
            </>
        );
    }
);
