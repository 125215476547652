import { createSlice } from "@reduxjs/toolkit";

import { getNickName } from "@utils/nickName";

import { getFrensList } from "./frens.thunk";

export interface IFrensListState {
    earn: number;
    earnGamePasses: number;
    firstName: string;
    lastName: string;
    nickName: string;
    username: string;
}

export interface IFrensState {
    frens: IFrensListState[] | null;
    isLoading: boolean;
    receivedFrensCount: number;
}

const initialState: IFrensState = {
    frens: null,
    isLoading: false,
    receivedFrensCount: 0,
};

export const frensSlice = createSlice({
    initialState,
    name: "frens",
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getFrensList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getFrensList.fulfilled, (state, action) => {
                const { payload } = action;
                const receivedFrens = payload.map((_) => {
                    return {
                        firstName: _.firstName,
                        lastName: _.lastName,
                        username: _.username,
                        earn: Number(_.earn),
                        earnGamePasses: Number(_.earnGamePasses),
                        nickName: getNickName(_),
                    };
                });
                state.frens = (state.frens ?? []).concat(receivedFrens);

                const uniqueUsers: any = [];
                const seenNicknames: any = {};

                state.frens.forEach((frens) => {
                    if (!seenNicknames[frens.nickName]) {
                        uniqueUsers.push(frens);
                        seenNicknames[frens.nickName] = true;
                    }
                });

                state.frens = uniqueUsers;

                state.receivedFrensCount = payload.length;
                state.isLoading = false;
            })
            .addCase(getFrensList.rejected, (state, action) => {
                state.isLoading = false;
                const { error } = action;
                // eslint-disable-next-line no-console
                console.error(error.message);
            });
    },
});

// export const actions = referralSlice.actions;

export default frensSlice.reducer;
