import { FC, memo, useEffect, useRef, useState } from "react";

import clsx from "clsx";

import { useAppDispatch } from "@hooks/useAppDispatch";
import { useAppSelector } from "@hooks/useAppSelector";

import { StandardSunSpinner } from "@atoms/SunSpinner/StandardSunSpinner";
import Typography from "@atoms/Typography";
import WindowBlock from "@atoms/WindowBlock";

import { TicketSVG } from "@svg/TicketSVG";

import { frensSelector } from "@redux/frens/frens.selectors";
import { getFrensList } from "@redux/frens/frens.thunk";

import styles from "./FrensList.module.scss";
import { IFrensList } from "./FrensList.types";

const FrensList: FC<IFrensList> = () => {
    const [limit] = useState(30);
    const [offset, setOffset] = useState(0);
    const dispatch = useAppDispatch();

    const {
        frens,
        receivedFrensCount,
        isLoading: isFrensListLoading,
    } = useAppSelector(frensSelector);

    useEffect(() => {
        if (!frens) {
            dispatch(getFrensList({ limit, offset }));
        }
    }, [dispatch, frens, limit, offset]);

    const anchorRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        handleScrollToAnchor();
                    }
                });
            },
            { threshold: 1 }
        );

        if (anchorRef.current) {
            observer.observe(anchorRef.current);
        }

        return () => {
            if (anchorRef.current) {
                observer.unobserve(anchorRef?.current);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [anchorRef.current]);

    const handleScrollToAnchor = () => {
        if (receivedFrensCount === limit) {
            setOffset((offset) => offset + limit);
        }
    };

    const frensLength = frens?.length ?? 0;

    if (isFrensListLoading) {
        return <StandardSunSpinner />;
    }

    return (
        <WindowBlock className={styles.wrapper}>
            {frensLength === 0 && (
                <div className={styles.empty}>
                    <Typography variant="textBold">Frens list</Typography>
                    <Typography variant="captionRegular">
                        No frens yet
                    </Typography>
                </div>
            )}
            {frensLength > 0 && (
                <>
                    <Typography className={styles.title} variant="textBold">
                        Frens list
                    </Typography>

                    <div className={styles.list}>
                        <div className={styles.head}>
                            <div className={styles.row}>
                                <Typography
                                    className={clsx(
                                        styles.name,
                                        styles["head-cell"]
                                    )}
                                    variant="captionRegular"
                                >
                                    Nickname
                                </Typography>
                                <Typography
                                    className={clsx(
                                        styles.pass,
                                        styles["head-cell"]
                                    )}
                                    variant="captionRegular"
                                >
                                    Game pass
                                </Typography>
                                <Typography
                                    className={clsx(
                                        styles.points,
                                        styles["head-cell"]
                                    )}
                                    variant="captionRegular"
                                >
                                    Points
                                </Typography>
                            </div>
                        </div>
                        <div className={styles.body}>
                            {frens?.map(
                                ({ nickName, earnGamePasses, earn }, index) => (
                                    <div key={index} className={styles.row}>
                                        <Typography
                                            className={clsx(
                                                styles.name,
                                                styles["body-cell"]
                                            )}
                                            variant="captionRegular"
                                        >
                                            {nickName}
                                        </Typography>
                                        <Typography
                                            className={clsx(
                                                styles.pass,
                                                styles["body-cell"]
                                            )}
                                            variant="captionRegular"
                                        >
                                            {earnGamePasses ? "+" : ""}
                                            {earnGamePasses} <TicketSVG />
                                        </Typography>
                                        <Typography
                                            className={clsx(
                                                styles.points,
                                                styles["body-cell"]
                                            )}
                                            variant="captionRegular"
                                        >
                                            {earn}
                                        </Typography>
                                    </div>
                                )
                            )}
                            {!!frensLength && <div ref={anchorRef} />}
                        </div>
                    </div>
                </>
            )}
        </WindowBlock>
    );
};

export default memo(FrensList);
