import clsx from "clsx";

import Typography from "@atoms/Typography";
import WindowBlock from "@atoms/WindowBlock";

import styles from "./InfoBlock.module.scss";
import { IInfoBlock } from "./InfoBlock.types";

const InfoBlock: FCC<IInfoBlock> = ({ icon, mode, name, children }) => {
    return (
        <WindowBlock
            className={clsx(styles.wrapper, styles[`wrapper_${mode}`])}
        >
            <Typography className={styles.top} variant="textSemibold">
                {children}
                <div className={styles.icon}>{icon}</div>
            </Typography>
            <Typography className={styles.name} variant="captionRegular">
                {name}
            </Typography>
        </WindowBlock>
    );
};

export default InfoBlock;
