import { FC } from "react";

import { ISvg } from "./SVG.types";

export const SuccessSimpleSVG: FC<ISvg> = (props) => {
    return (
        <svg
            fill="none"
            height="20"
            viewBox="0 0 20 20"
            width="20"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M17.9423 6.06717L7.94229 16.0672C7.88425 16.1253 7.81531 16.1714 7.73944 16.2028C7.66357 16.2343 7.58224 16.2505 7.5001 16.2505C7.41797 16.2505 7.33664 16.2343 7.26077 16.2028C7.18489 16.1714 7.11596 16.1253 7.05792 16.0672L2.68292 11.6922C2.56564 11.5749 2.49976 11.4158 2.49976 11.25C2.49976 11.0841 2.56564 10.9251 2.68292 10.8078C2.80019 10.6905 2.95925 10.6246 3.1251 10.6246C3.29096 10.6246 3.45002 10.6905 3.56729 10.8078L7.5001 14.7414L17.0579 5.18279C17.1752 5.06552 17.3343 4.99963 17.5001 4.99963C17.666 4.99963 17.825 5.06552 17.9423 5.18279C18.0596 5.30007 18.1255 5.45913 18.1255 5.62498C18.1255 5.79083 18.0596 5.94989 17.9423 6.06717Z"
                fill="white"
            />
        </svg>
    );
};
