import clsx from "clsx";

import styles from "./WindowBlock.module.scss";
import { IWindowBlock } from "./WindowBlock.types";

const WindowBlock: FCC<IWindowBlock> = ({ children, className, onClick }) => {
    return (
        <div
            className={clsx(styles.wrapper, className, {
                [styles.wrapper_clickable]: !!onClick,
            })}
            onClick={onClick}
        >
            {children}
        </div>
    );
};

export default WindowBlock;
