import { memo } from "react";

import MainLayout from "@organisms/layouts/MainLayout";

import LeaderBoardList from "./components/LeaderBoardList";

const LeaderBoardScreen = () => {
    return (
        <MainLayout
            withGoBack
            additionalScrollHeight={20}
            subtitle="Invite frens to get here"
            title="Leaderboard"
        >
            <LeaderBoardList />
        </MainLayout>
    );
};

export default memo(LeaderBoardScreen);
