import clsx from "clsx";

import { childByStatus } from "./Button.const";
import styles from "./Button.module.scss";
import { IButton } from "./Button.types";

const Button: FCC<IButton> = ({
    children,
    variant = "standard",
    autoSize,
    className,
    status = "none",
    ...otherProps
}) => {
    return (
        <button
            className={clsx(
                styles.button,
                styles[`button_${variant}`],
                className,
                {
                    [styles["button_full-size"]]: !autoSize,
                    [styles.button_disabled]:
                        !!otherProps.disabled || variant === "disabled",
                    [styles.button_icon]:
                        status === "loading" || status === "done",
                }
            )}
            {...otherProps}
        >
            {childByStatus[status] ?? children}
        </button>
    );
};

export default Button;
