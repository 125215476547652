import { FC } from "react";

import { ISvg } from "./SVG.types";

export const ArrowRightSVG: FC<ISvg> = (props) => {
    return (
        <svg
            fill="none"
            height="16"
            viewBox="0 0 16 16"
            width="16"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M11.265 8.26505L6.265 13.265C6.19391 13.3313 6.09989 13.3673 6.00274 13.3656C5.90559 13.3639 5.8129 13.3246 5.74419 13.2559C5.67548 13.1872 5.63613 13.0945 5.63441 12.9973C5.6327 12.9002 5.66876 12.8061 5.735 12.735L10.4694 8.00005L5.735 3.26505C5.66876 3.19396 5.6327 3.09993 5.63441 3.00278C5.63613 2.90563 5.67548 2.81294 5.74419 2.74423C5.8129 2.67553 5.90559 2.63617 6.00274 2.63446C6.09989 2.63274 6.19391 2.66881 6.265 2.73505L11.265 7.73505C11.3352 7.80536 11.3747 7.90067 11.3747 8.00005C11.3747 8.09942 11.3352 8.19473 11.265 8.26505Z"
                fill="white"
            />
        </svg>
    );
};
