export function getTimeUntilUpdate(
    registrationTime: string,
    roundToMinutes: number,
    searchTime: number
): string {
    const registrationDate = new Date(registrationTime);
    const currentDate = new Date(new Date().toISOString()); // Get current date and time in UTC

    // Округляем время регистрации до ближайшего меньшего времени кратного roundToMinutes
    const roundedRegistrationTime = new Date(
        Math.floor(registrationDate.getTime() / (roundToMinutes * 60 * 1000)) *
            (roundToMinutes * 60 * 1000)
    );

    // Разница в миллисекундах между текущим временем и округленным временем регистрации
    const diffInMillis =
        currentDate.getTime() - roundedRegistrationTime.getTime();

    // Переводим миллисекунды в минуты
    const diffInMinutes = diffInMillis / (60 * 1000);

    // Остаток от деления разницы на время поиска
    const remainder = diffInMinutes % searchTime;

    // Сколько минут осталось до следующего обновления
    const minutesUntilUpdate = searchTime - remainder;

    // Переводим минуты до обновления в миллисекунды
    const millisUntilUpdate = minutesUntilUpdate * 60 * 1000;

    // Время следующего обновления
    // @ts-ignore
    const updateDate = new Date(currentDate.getTime() + millisUntilUpdate);

    // Форматируем время до обновления в HH:MM:SS
    const hours = Math.floor(minutesUntilUpdate / 60);
    const minutes = Math.floor(minutesUntilUpdate % 60);
    const seconds = Math.floor((minutesUntilUpdate * 60) % 60);

    // Дополнение нулями для формата HH:MM:SS
    const formattedTime = `${String(hours).padStart(2, "0")}:${String(
        minutes
    ).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;

    return formattedTime;
}
