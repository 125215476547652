export class PreloadScene extends Phaser.Scene {
    constructor() {
        super("PreloadScene");
    }

    preload() {
        const loadingText = this.add.text(
            this.cameras.main.width / 2,
            this.cameras.main.height / 2 - 130,
            "Loading...",
            { color: "#FFF", fontSize: "32px" }
        );
        loadingText.setOrigin(0.5, 0.5);

        // Добавление полосы загрузки
        const progressBar = this.add.graphics();
        const progressBox = this.add.graphics();

        progressBox.fillStyle(0x222222, 0.8);
        progressBox.fillRect(
            this.cameras.main.width / 2 - 160,
            this.cameras.main.height / 2 - 80,
            320,
            50
        );

        this.load.on("progress", (value: any) => {
            progressBar.clear();
            progressBar.fillStyle(0xffffff, 1);
            progressBar.setDepth(10);
            progressBar.fillRect(
                this.cameras.main.width / 2 - 140,
                this.cameras.main.height / 2 - 70,
                280 * value,
                30
            );
        });

        this.load.on("complete", () => {
            progressBar.destroy();
            progressBox.destroy();
            loadingText.destroy();
        });

        // Здесь загружаются все ресурсы игры
        this.load.setBaseURL(window.location.origin);

        this.load.setPath("assets");

        this.load.image("background", "back_stadium_JB_700px.png");

        this.load.image("cloud1", "cloud1.png");
        this.load.image("cloud2", "cloud2.png");
        this.load.image("cloud3", "cloud3.png");
        this.load.image("cloud4", "cloud4.png");
        this.load.image("cloud5", "cloud5.png");
        this.load.image("cloud6", "cloud6.png");
        this.load.image("cloud7", "cloud7.png");
        this.load.image("cloud8", "cloud8.png");
        this.load.image("cloud9", "cloud9.png");
        this.load.image("cloud10", "cloud10.png");
        this.load.image("cloud11", "cloud11.png");
        this.load.image("cloud12", "cloud12.png");
        this.load.image("cloud13", "cloud13.png");
        this.load.image("cloud14", "cloud14.png");
        this.load.image("cloud15", "cloud15.png");
        this.load.image("cloud16", "cloud16.png");
        this.load.image("cloud17", "cloud17.png");
        this.load.image("cloud18", "cloud18.png");
        this.load.image("ball", "ball.png");

        this.load.image("frame1", "effects/1.png");
        this.load.image("frame2", "effects/2.png");
        this.load.image("frame3", "effects/3.png");
        this.load.image("frame4", "effects/4.png");

        this.load.image("progressFill", "progressFill.png");
        this.load.image("progressBg", "progressBg.png");
        this.load.image("barIcon", "BarIcon.png");

        this.load.image("button", "button.png");

        this.load.image("stars", "star_sky.png");

        this.load.spineAtlas("ballAtlas", "spine/ball.atlas");
        this.load.spineJson("ballJson", "spine/ball.json");

        this.load.spineAtlas("baloonAtlas", "spine/Sky_props_JB_balloon.atlas");
        this.load.spineJson("baloonJson", "spine/Sky_props_JB_balloon.json");

        this.load.spineAtlas("planeAtlas", "spine/Sky_props_JB_plane.atlas");
        this.load.spineJson("planeJson", "spine/Sky_props_JB_plane.json");

        this.load.spineAtlas("plane2Atlas", "spine/Sky_props_JB_plane2.atlas");
        this.load.spineJson("plane2Json", "spine/Sky_props_JB_plane2.json");

        this.load.spineAtlas("nloAtlas", "spine/Sky_props_JB_nlo.atlas");
        this.load.spineJson("nloJson", "spine/Sky_props_JB_nlo.json");

        this.load.spineAtlas("saturnAtlas", "spine/Sky_props_JB_saturn.atlas");
        this.load.spineJson("saturnJson", "spine/Sky_props_JB_saturn.json");

        this.load.spineAtlas(
            "footballGuy",
            "spine/football_guy_cut_halfside_300x540.atlas"
        );
        this.load.spineJson(
            "footballGuy",
            "spine/football_guy_cut_halfside_300x540.json"
        );

        this.load.spineAtlas("rocketAtlas", "spine/Sky_props_JB_rocket.atlas");
        this.load.spineJson("rocketJson", "spine/Sky_props_JB_rocket.json");
        // Загрузите все остальные ресурсы, которые вам нужны
    }

    create() {
        this.scene.start("Game");
    }
}
