import { FC } from "react";

import Button from "@atoms/Button";
import Typography from "@atoms/Typography";

import MainLayout from "@organisms/layouts/MainLayout";

import WarningSVG from "@svg/WarningSVG";

import style from "./OopsScreen.module.scss";

const OopsScreen: FC = () => {
    return (
        <MainLayout additionalScrollHeight={100} hasNavbar={false} title="">
            <div className={style.warningContainer}>
                <WarningSVG />
            </div>
            <Typography className={style.textBold} variant="h1Bold">
                OOPS! <br /> Something went <br /> wrong
            </Typography>
            <Typography className={style.textRegular} variant="textRegular">
                Make sure you have a stable internet <br /> connection and
                restart the app.
            </Typography>
            <Button
                className={style.button}
                variant="gradient"
                onClick={() => window.location.reload()}
            >
                Restart
            </Button>
        </MainLayout>
    );
};

export default OopsScreen;
