import { FC } from "react";

import IconBlock from "@atoms/IconBlock";
import SunSpinner from "@atoms/SunSpinner";
import Typography from "@atoms/Typography";
import WindowBlock from "@atoms/WindowBlock";

import { TicketSVG } from "@svg/TicketSVG";

import style from "../PlayScreen.module.scss";
import { IPassCounter } from "../types";

const PassCounter: FC<IPassCounter> = ({ passes, isLoading }) => {
    return (
        <WindowBlock className={style.passCounter}>
            <Typography variant="textRegular">Game passes</Typography>
            <div className={style.countPassLabel}>
                {!isLoading ? (
                    <Typography variant="h3Bold">{passes}</Typography>
                ) : (
                    <SunSpinner height="20px" width="20px" />
                )}
                <IconBlock className={style.icon} mode="gray">
                    <TicketSVG />
                </IconBlock>
            </div>
        </WindowBlock>
    );
};

export default PassCounter;
