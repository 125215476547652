import { FC, memo } from "react";

import Button from "@atoms/Button";
import Typography from "@atoms/Typography";

import Modal from "@molecules/Modal";

import img from "@images/goldTicket.png";
import bg from "@images/shelfModalBg.svg";

import styles from "./ShelfExtraGameModal.module.scss";
import { IShelfExtraGameModal } from "./ShelfExtraGameModal.types";

const ShelfExtraGameModal: FC<IShelfExtraGameModal> = ({
    buttonPoints,
    onClick,
    description,
    title,
    ...modalProps
}) => {
    return (
        <Modal {...modalProps} title={title} titleClassName={styles.title}>
            <div className={styles.content}>
                <Typography className={styles.subtitle} variant="text2Regular">
                    {description}
                </Typography>

                <img alt="" className={styles.bg} src={bg} />
                <img alt="" className={styles.img} src={img} />

                <Button className={styles.btn} onClick={onClick}>
                    Pay {buttonPoints.toLocaleString("ru-RU")} point
                    {buttonPoints > 1 ? "s" : ""}
                </Button>
            </div>
        </Modal>
    );
};

export default memo(ShelfExtraGameModal);
