import { createSlice } from "@reduxjs/toolkit";

import { getNickName } from "@utils/nickName";

import { getLeaderBoardsData } from "./leaderboards.thunk";

export interface ILeaderState {
    earn: number;
    firstName: string;
    lastName: string;
    nickName: string;
    position: number;
    username: string;
}

export interface ILeadersState {
    isLoading: boolean;
    leaders: ILeaderState[];
    receivedLeadersCount: number;
    stats: ILeaderState | null;
}

const initialState: ILeadersState = {
    stats: null,
    leaders: [],
    isLoading: false,
    receivedLeadersCount: 0,
};

export const leaderBoardsSlice = createSlice({
    initialState,
    name: "leaderBoards",
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getLeaderBoardsData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getLeaderBoardsData.fulfilled, (state, action) => {
                const {
                    payload: { stats, leadersBoard },
                } = action;
                state.stats = {
                    ...stats,
                    earn: Number(stats.earn),
                    position: Number(stats.position),
                    nickName: getNickName(stats),
                };

                const receivedLeaders = leadersBoard.map((leader) => {
                    return {
                        ...leader,
                        earn: Number(leader.earn),
                        position: Number(leader.position),
                        nickName: getNickName(leader),
                    };
                });
                state.leaders = state.leaders.concat(receivedLeaders);

                const uniqueUsers: any = [];
                const seenNicknames: any = {};

                state.leaders.forEach((leader) => {
                    if (!seenNicknames[leader.nickName]) {
                        uniqueUsers.push(leader);
                        seenNicknames[leader.nickName] = true;
                    }
                });
                state.leaders = uniqueUsers;

                state.receivedLeadersCount = receivedLeaders.length;
                state.isLoading = false;
            })
            .addCase(getLeaderBoardsData.rejected, (state, action) => {
                state.isLoading = false;
                const { error } = action;
                // eslint-disable-next-line no-console
                console.error(error.message);
            });
    },
});

// export const actions = referralSlice.actions;

export default leaderBoardsSlice.reducer;
