import { useState } from "react";

import { v4 as uuidv4 } from "uuid";

import {
    CustomGradientSVG,
    ICustomGradientSVG,
} from "@svg/gradients/CustonGradientSVG";

export const useGetCustomGradient = (
    colors: ICustomGradientSVG["gradientColors"]
) => {
    const gradientId = useState(() => uuidv4())[0];

    return {
        CustomGradientSVG: (
            <CustomGradientSVG
                gradientColors={colors}
                gradientId={gradientId}
            />
        ),
        gradientId,
    };
};
