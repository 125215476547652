import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { routes } from "@constants/routes";

import { useAppDispatch } from "@hooks/useAppDispatch";
import { useAppSelector } from "@hooks/useAppSelector";

import Button from "@atoms/Button";
import Typography from "@atoms/Typography";

import MainLayout from "@organisms/layouts/MainLayout";

import PassCounter from "@screens/PlayScreen/components/PassCounter";
import PassEarner from "@screens/PlayScreen/components/PassEarner";

import { RewardSVG } from "@svg/RewardSVG";

import { userSelector } from "@redux/users/users.selectors";
import { startUserGame } from "@redux/users/users.thunk";

import style from "./CheckResults.module.scss";

const CheckResultsScreen = () => {
    const [points, setPoints] = useState<string | null>("0");
    const navigate = useNavigate();

    const users = useAppSelector(userSelector);
    const dispatch = useAppDispatch();

    useEffect(() => {
        const searchParams = new URLSearchParams(
            window.location.search.slice(1)
        );

        setPoints(searchParams.get("points"));
    }, [dispatch, users.userData]);

    return (
        <MainLayout
            withGoBack
            additionalScrollHeight={100}
            navigateURL={routes.play}
            title={<>Tap rush</>}
        >
            <div className={style.rewardBlock}>
                <RewardSVG />
                <div className={style.infoBlock}>
                    <div className={style.pointsText}>
                        <Typography variant="h1Bold">{points}</Typography>
                        <Typography
                            style={{ marginBottom: 6 }}
                            variant="text2Bold"
                        >
                            POINTS
                        </Typography>
                    </div>
                    <Typography variant="text2Regular">Received</Typography>
                </div>
                <Typography
                    style={{ textAlign: "center" }}
                    variant="text2Regular"
                >
                    {Number(points) > 500 ? (
                        <>Fantastic effort! Keep going to reach new heights!</>
                    ) : (
                        <>
                            Don`t give up! <br /> Practice makes perfect, try
                            again!
                        </>
                    )}
                </Typography>
            </div>
            <Button
                className={style.playButton}
                disabled={
                    Number(users.userData?.gamePasses) +
                        Number(users.userData?.extraGamePasses) ===
                    0
                }
                variant="gradient"
                onClick={() => {
                    dispatch(startUserGame()).then((res: any) => {
                        const gameId = res.payload.data.data.game.id;

                        if (gameId !== undefined) {
                            navigate(`${routes.game}?gameId=${gameId}`);
                        }
                    });
                }}
            >
                Play again
            </Button>

            <PassCounter
                isLoading={users.isLoading}
                passes={
                    Number(users.userData?.gamePasses) +
                    Number(users.userData?.extraGamePasses)
                }
            />

            <PassEarner />
        </MainLayout>
    );
};

export default CheckResultsScreen;
