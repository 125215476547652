import oneClosedPresent from "@images/oneClosedPresent.png";
import oneOpenedPresent from "@images/oneOpenedPresent.png";
import threeClosedPresents from "@images/threeClosedPresents.png";
import threeOpenedPresents from "@images/threeOpenedPresents.png";
import twoClosedPresents from "@images/twoClosedPresents.png";
import twoOpenedPresents from "@images/twoOpenedPresents.png";

import {
    TShelfPerksModalStage,
    TShelfPresentsAmount,
} from "./ShelfPerksModal.types";

export const presentsByAmountAndStage: Record<
    TShelfPerksModalStage,
    Record<TShelfPresentsAmount, string>
> = {
    close: {
        1: oneClosedPresent,
        2: twoClosedPresents,
        3: threeClosedPresents,
    },
    open: {
        1: oneOpenedPresent,
        2: twoOpenedPresents,
        3: threeOpenedPresents,
    },
} as const;

export const defaultStage: TShelfPerksModalStage = "close";
