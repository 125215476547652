// TODO: завести .env с нужно переменной окружения BASE_API_URL
export const BASE_URL = import.meta.env.VITE_APP_BACKEND_URL;

export const Headers = {
    "Content-Type": "application/json",
} as const;

export const API_URLS = {
    getEarn: "earn/quests/",
    users: "auth/",
    perks: "lootBox/items/",
    userInfo: "user/",
    games: "games/",
    getReferralData: "referral/",
    getFrensList: "referral/frens/list/",
    getLeaderBoards: "referral/leaderBoard/list/",
    getBoosts: "boosts/",
    getLootBoxData: "lootBox/",
} as const;
